import { v4 as uuidv4 } from "uuid";
import { Box, Typography } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { CustomizedBox } from "components/Custom/CustomizedBox";
import CustomizedButton from "components/Custom/CustomizedButton";
import ExpensesListTable from "components/Table/Logistic/ExpensesList";
import Confirmation from "components/UI/Confirmation";
import ItemTableModal from "components/UI/Modal/CheckedModal/ItemTableModal";
import { useModal } from "hooks/use-modal";
import { useRef, useState } from "react";
import { DropResult } from "react-beautiful-dnd";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IItem } from "types/Inventory/item";
import { IExpensesList } from "types/Logistic";
import { IDeliveryTrip } from "types/Logistic/deliveryTrip";
import PostDiscountAmount from "components/Table/Logistic/ExpensesList/PostDIscountAmount";
import { vatItemTypeFormatter } from "utils/Formatter/Global";
import { useDisable } from "hooks/use-disable";
import ExpnesesSummary from "./ExpensesSummary";

const ExpensesList = () => {
  const gridRef = useRef<AgGridReact<any>>(null);
  const { control } = useFormContext<IDeliveryTrip>();
  const { fields, remove, append, move } = useFieldArray({
    control,
    name: "expenses_list",
  });
  const { t } = useTranslation();

  const [disabled] = useDisable();

  const dragEndHandler = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    move(result.source.index, result.destination.index);
  };

  const [openDeleteItemConfirmation, setOpenDeleteItemConfirmation] =
    useState<boolean>(false);
  const [deletedIndex, setDeletedIndex] = useState<number | undefined>(
    undefined
  );

  const openDeleteItemConfirmationHandler = (index: number) => {
    setOpenDeleteItemConfirmation(true);
    setDeletedIndex(index);
  };

  const closeDeleteItemConfirmationHandler = () => {
    setOpenDeleteItemConfirmation(false);
    setDeletedIndex(undefined);
  };

  const deleteItemConfirmationAction = () => {
    if (deletedIndex || deletedIndex === 0) {
      remove(deletedIndex);
      // setItemIds((prev) => prev.filter((_, index) => index !== deletedIndex));
      // setItemIdsSnapshot((prev) =>
      //   prev.filter((_, index) => index !== deletedIndex)
      // );
    }
    closeDeleteItemConfirmationHandler();
  };

  const {
    modal: itemModal,
    openModalHandler: openItemModalHandler,
    closeModalHandler: closeItemModalHandler,
  } = useModal();

  const addItemsHandler = () => {
    const selectedItems = gridRef?.current?.api.getSelectedRows();
    if (selectedItems) {
      selectedItems.forEach((item_details: IItem) => {
        const isZeroWithholding: boolean = [
          "SP00008",
          "SP00009",
          "SP00010",
          "SP00011",
          "SP00012",
          "SP00013",
        ].includes(item_details.unique_id);
        let formatItems: IExpensesList = {
          unique_id: uuidv4(),
          item_id: item_details.id || 0,
          item_unique_id: item_details.unique_id || "",
          item_name: item_details.name || "",
          item_sku_desc: item_details.description,
          qty: 1,
          uom_id: item_details.stock_uom_id || 0,
          uom_name: item_details.stock_uom_name || "",
          uom_conversion_list: item_details.uom_conversion_list,
          discount: 0,
          pre_vat_amount: 0,
          price_per_unit: item_details.purchase_price ?? 0,
          withholding_tax_type: isZeroWithholding ? "0" : "3",
          withholding_tax_value: 0,
          vat_percentage: vatItemTypeFormatter(
            item_details.purchase_vat_type || ""
          ),
        };

        append(formatItems);
      });
    }
    closeItemModalHandler();
  };

  return (
    <CustomizedBox maxWidth={1650}>
      <Box display="flex" gap={0.5}>
        <Typography fontWeight={600} mb={1}>
          {t("logistic.expenses_list.index")}
        </Typography>
        <Typography color="error.main">*</Typography>
      </Box>
      <ExpensesListTable
        fields={fields}
        dragEndHandler={dragEndHandler}
        openDeleteItemConfirmationHandler={openDeleteItemConfirmationHandler}
      />
      <Box mt={2}>
        {!disabled && (
          <CustomizedButton
            startAddIcon
            variant="outlined"
            title={t("logistic.expenses_list.add")}
            onClick={openItemModalHandler}
          />
        )}
      </Box>
      <PostDiscountAmount />
      <ExpnesesSummary />
      <Confirmation
        title="ลบรายรับพนักงานขับรถ"
        open={openDeleteItemConfirmation}
        handleClose={closeDeleteItemConfirmationHandler}
        action={deleteItemConfirmationAction}
      />
      <ItemTableModal
        ref={gridRef}
        itemModal={itemModal}
        closeItemModalHandler={closeItemModalHandler}
        addItemsHandler={addItemsHandler}
        isService
        isExpenses
      />
    </CustomizedBox>
  );
};

export default ExpensesList;
