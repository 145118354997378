import { useEffect, useMemo, useState } from "react";
import { IMenuOption } from "types/global";
import { IAccessPermission } from "types/Auth/role";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import { BILLING_NOTE_VIEW } from "services/AgGrid/SalesAggrid";

export const useDeliveryTripOption = (
  status?: string | null,
  isEditing?: boolean,
  deliveryTripPermissions?: IAccessPermission | null,
  uniqueId?: string
) => {
  const defaultOptions: IMenuOption[] = useMemo(
    () =>
      [
        deliveryTripPermissions?.update && {
          value: "แก้ไข",
          disabled: isEditing,
        },
        deliveryTripPermissions?.create && {
          value: "คัดลอก",
          disabled: false,
        },
        deliveryTripPermissions?.cancel && {
          value: "ยกเลิก",
          disabled: false,
        },
      ] as IMenuOption[],
    [isEditing, deliveryTripPermissions]
  );

  const [selectModifyOptions, setSelectedModifyOptions] =
    useState<IMenuOption[]>(defaultOptions);

  const [isCancellable, setIsCancellable] = useState<boolean>(true);

  useEffect(() => {
    const getBillingNote = async () => {
      const graphQLClientWithHeader: GraphQLClient =
        createGraphQLClientWithMiddleware("sales");

      const { BillingNoteViewsAggrid } = await graphQLClientWithHeader.request(
        BILLING_NOTE_VIEW,
        {
          aggridInput: {
            startRow: 0,
            endRow: 1,
            filterModel: {
              reference_delivery_trip_unique_id_list: uniqueId &&
                uniqueId?.trim() !== "" && {
                  type: "hasSome",
                  filterType: "array",
                  values: [uniqueId],
                },
            },
            sortModel: [],
          },
        }
      );

      if (BillingNoteViewsAggrid.count > 0) {
        setIsCancellable(false);
      }
    };
    if (status === "finished") {
      getBillingNote();
    }
  }, [status, uniqueId]);

  useEffect(() => {
    switch (status) {
      case "draft":
        setSelectedModifyOptions(
          [defaultOptions[1], defaultOptions[2]].filter(Boolean)
        ); // ยกเลิก, คัดลอก
        break;
      case "cancelled":
        setSelectedModifyOptions([defaultOptions[1]].filter(Boolean)); // คัดลอก
        break;
      case "finished":
        if (isCancellable) {
          setSelectedModifyOptions(
            [defaultOptions[0], defaultOptions[1]].filter(Boolean)
          ); // คัดลอก
        } else {
          setSelectedModifyOptions([defaultOptions[1]].filter(Boolean)); // คัดลอก
        }
        break;
      default:
        setSelectedModifyOptions(defaultOptions.filter(Boolean));
        break;
    }
  }, [defaultOptions, isCancellable, status]);

  return selectModifyOptions;
};
